var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FilterSelection",
    {
      attrs: {
        disabled: _vm.disabled,
        "get-item-string-compare": _vm.getCompositionName
      },
      scopedSlots: _vm._u([
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            return [_c("AgentComposition", { attrs: { agent_ids: item } })]
          }
        }
      ]),
      model: {
        value: _vm.internalValue,
        callback: function($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue"
      }
    },
    [
      _c(
        "FormDropdown",
        {
          attrs: {
            options: _vm.options,
            disabled: _vm.disabled,
            "text-no-selection": "Any agent composition",
            "text-single-selection": "1 agent composition selected",
            "text-multiple-selection":
              _vm.internalValue.length + " compositions selected"
          },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        _vm._l(_vm.displayCompositions, function(composition, index) {
          return _c(
            "DropdownItem",
            {
              key: index,
              attrs: {
                selected: _vm.isCompositionActive(composition),
                disabled: _vm.disabled
              },
              nativeOn: {
                click: function($event) {
                  return _vm.toggle(composition)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "composition-container" },
                [
                  _c("AgentComposition", { attrs: { agent_ids: composition } }),
                  composition.picks
                    ? _c("div", { staticClass: "ml-2" }, [
                        _c("strong", [_vm._v(_vm._s(composition.picks))]),
                        _c("span", { staticClass: "small ml-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("percentage")(
                                composition.picks,
                                _vm.totalPicks
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }