var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.on_submit.apply(null, arguments)
            },
            reset: _vm.on_reset
          }
        },
        [
          _c("div", { staticClass: "filters" }, [
            _c(
              "div",
              { staticClass: "filters--item filters--item__teams" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Opponent teams:")
                ]),
                _c("TeamFilter", {
                  attrs: {
                    disabled: _vm.disabled,
                    "exclude-teams": [_vm.team_id]
                  },
                  model: {
                    value: _vm.form.teams_vs,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "teams_vs", $$v)
                    },
                    expression: "form.teams_vs"
                  }
                })
              ],
              1
            ),
            _vm.compositions.length
              ? _c(
                  "div",
                  { staticClass: "filters--item filters--item__agents" },
                  [
                    _c("div", { staticClass: "filters--item--title" }, [
                      _vm._v("Own compositions:")
                    ]),
                    _c("AgentCompositionFilter", {
                      attrs: {
                        disabled: _vm.disabled,
                        compositions: _vm.compositions
                      },
                      model: {
                        value: _vm.form.agent_comps,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "agent_comps", $$v)
                        },
                        expression: "form.agent_comps"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "filters--item filters--item__regions" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Region:")
                ]),
                _c("RegionFilter", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.form.platforms,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "platforms", $$v)
                    },
                    expression: "form.platforms"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__period" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Period:")
                ]),
                _c("PeriodFilter", {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.filter_period,
                    callback: function($$v) {
                      _vm.filter_period = $$v
                    },
                    expression: "filter_period"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filters--item filters--item__vod" },
              [
                _c("div", { staticClass: "filters--item--title" }, [
                  _vm._v("Video:")
                ]),
                _c(
                  "b-form-group",
                  { attrs: { "label-for": "filter-has-vod" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "mb-1",
                        attrs: { id: "filter-has-vod" },
                        model: {
                          value: _vm.form.has_vod,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "has_vod", $$v)
                          },
                          expression: "form.has_vod"
                        }
                      },
                      [_vm._v(" only with VOD ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12" } }, [
                _c(
                  "div",
                  { staticClass: "btn-actions" },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c(
                          "button",
                          {
                            ref: "searchBtn",
                            staticClass: "btn btn-warning",
                            class: { highlight: _vm.changed },
                            attrs: { type: "submit", disabled: _vm.disabled }
                          },
                          [_vm._v(" Search ")]
                        ),
                        _vm.$refs.searchBtn
                          ? _c(
                              "BTooltip",
                              {
                                attrs: {
                                  show: _vm.changed,
                                  target: _vm.$refs.searchBtn,
                                  triggers: "manual"
                                }
                              },
                              [_vm._v(" Apply new filters ")]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "reset",
                              variant: "outline-warning",
                              disabled: _vm.disabled
                            }
                          },
                          [_vm._v(" Reset ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }